@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;700&display=swap");
@import url('https://fonts.cdnfonts.com/css/switzer');

body {
  font-family: 'Switzer'
}

@tailwind base;
@tailwind components;

/* @import url("./app/_form-control.css"); */

@tailwind utilities;

/* @import url("./app/globals.css"); */
@layer components {
 
  .form-control {
    @apply transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-1  focus:ring-opacity-20  focus:border-opacity-40 ;
    &:disabled,
    &[readonly] {
      @apply bg-slate-100 cursor-not-allowed  dark:border-transparent;
    }
  }
  .form-label {
    @apply inline-block mb-2;
  }
  /* Default Sizes */
  .form-control-sm {
    @apply text-xs py-1.5 px-2;
  }
  .form-control-lg {
    @apply text-lg py-1.5 px-4;
  }

  /* Form Control Rounded */
  .form-control-rounded {
    @apply rounded-full;
  }
}
@layer base {
  :root {
    /*background*/
    --background: var(--tertiary-white-100);

    /* Tertiary Colors: Blue */
    --tertiary-blue-100: hsl(203, 81%, 94%);
    --tertiary-blue-200: hsl(201, 80%, 86%);
    --tertiary-blue-300: hsl(199, 80%, 74%);
    --tertiary-blue-400: hsl(198, 78%, 64%);
    --tertiary-blue-500: hsl(198, 74%, 48%);
    --tertiary-blue-600: hsl(200, 82%, 39%);
    --tertiary-blue-700: hsl(201, 80%, 32%);
    --tertiary-blue-800: hsl(200, 76%, 27%);
    --tertiary-blue-900: hsl(201, 67%, 24%);
    --tertiary-blue-950: hsl(204, 68%, 16%);

    /* Tertiary Colors: Red */
    --tertiary-red-100: hsl(0, 67%, 94%);
    --tertiary-red-200: hsl(2, 70%, 89%);
    --tertiary-red-300: hsl(1, 68%, 82%);
    --tertiary-red-400: hsl(1, 65%, 71%);
    --tertiary-red-500: hsl(1, 62%, 60%);
    --tertiary-red-600: hsl(1, 52%, 51%);
    --tertiary-red-700: hsl(2, 53%, 42%);
    --tertiary-red-800: hsl(1, 51%, 35%);
    --tertiary-red-900: hsl(2, 46%, 31%);
    --tertiary-red-950: hsl(1, 54%, 15%);

    /* Tertiary Colors: White */
    --tertiary-white-100: hsl(0, 0%, 94%);
    --tertiary-white-200: hsl(0, 0%, 86%);
    --tertiary-white-300: hsl(0, 0%, 74%);
    --tertiary-white-400: hsl(0, 0%, 60%);
    --tertiary-white-500: hsl(0, 0%, 49%);
    --tertiary-white-600: hsl(0, 0%, 40%);
    --tertiary-white-700: hsl(0, 0%, 32%);
    --tertiary-white-800: hsl(0, 0%, 27%);
    --tertiary-white-900: hsl(0, 0%, 24%);
    --tertiary-white-950: hsl(0, 0%, 16%);
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    /*background*/
    --background: var(--tertiary-white-100);
    /* Primary Colors */

    /* Tertiary Colors: Blue */
    --tertiary-blue-100: hsl(203, 81%, 94%);
    --tertiary-blue-200: hsl(201, 80%, 86%);
    --tertiary-blue-300: hsl(199, 80%, 74%);
    --tertiary-blue-400: hsl(198, 78%, 64%);
    --tertiary-blue-500: hsl(198, 74%, 48%);
    --tertiary-blue-600: hsl(200, 82%, 39%);
    --tertiary-blue-700: hsl(201, 80%, 32%);
    --tertiary-blue-800: hsl(200, 76%, 27%);
    --tertiary-blue-900: hsl(201, 67%, 24%);
    --tertiary-blue-950: hsl(204, 68%, 16%);
  }
}

/* Custom class to override Tailwind CSS styles for react-select */
.custom-select-override .react-select__control,
.custom-select-override .react-select__input-container input {
  outline: none !important;
  cursor: pointer;
  --tw-ring-color: transparent !important;
  --tw-ring-shadow: none !important;
  --tw-ring-offset-shadow: none !important;
  --tw-ring-offset-color: transparent !important;
}

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner {
  -moz-appearance: textfield; /* Firefox */
}

.blur-text {
  filter: blur(4px);
  color: transparent; /* Optional for hiding the blurred text */
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Creates a shadow effect */
  pointer-events: none; /* Prevent interaction with blurred text */
}

.tabsContainer button:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}