.appDownload {
    display: flex;
    padding: 50px 160px;
    align-items: center;
    justify-content: space-between;
    background-color: #F9F9F9;
    column-gap: 80px;
    row-gap: 40px;

    &>img {
        width: 300px;
        height: 500px;
        border-radius: 10px;
    }

    &>div {
        &>h3 {
            font-size: clamp(18px, 3vw, 30px);
            font-weight: 600;

            &>span {
                color: #1B64F2;
            }
        }

        &>p {
            font-size: clamp(14px, 1.4vw, 18px);
            margin-top: 10px;
        }

        &>div {
            display: flex;
            align-items: center;
            margin-top: 30px;
            column-gap: 20px;
            cursor: pointer;

            &>button {
                width: 200px;
                height: 60px;
                border-radius: 10px;

                &>img {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }
            }
        }
    }
}

@media (max-width: 750px) {
    .appDownload {
        flex-wrap: wrap;
        padding: 20px 30px;
        width: 100%;

        &>img {
            object-fit: contain;
        }

        &>div {
            &>div {
                &>button {
                    width: 150px;
                    height: 45px;
                }
            }
        }
    }
}