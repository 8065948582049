.hideScrollBar::-webkit-scrollbar {
  display: none;
}

.bg-custom-gradient {
  background-image: linear-gradient(to right, #030817 60%, #312e81 60%);
}

.react-select-container .react-select__input-container input {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  --tw-ring-color: transparent !important;
  --tw-ring-shadow: none !important;
}


@media screen and (min-width: 768px) {
  .container div {
    animation: scrolling 15s linear infinite;
    animation-delay: 1s;
  }
}

@media screen and (min-width: 1024px) {
  .container div {
    animation: scrolling 15s linear infinite;
    animation-delay: 1s;
  }
}
